var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',[_vm._v("編輯採購單")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"出貨單位","items":_vm.fromUnit,"outlined":"","dense":"","hide-details":"","disabled":""},model:{value:(_vm.localPayload.from),callback:function ($$v) {_vm.$set(_vm.localPayload, "from", $$v)},expression:"localPayload.from"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"進貨單位","items":_vm.unit,"outlined":"","dense":"","hide-details":"","disabled":""},model:{value:(_vm.localPayload.to),callback:function ($$v) {_vm.$set(_vm.localPayload, "to", $$v)},expression:"localPayload.to"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),(
          _vm.localPayload.items.filter(({ shipped }) => shipped !== 0).length !==
          0
        )?[_c('h3',{staticClass:"my-2"},[_vm._v("已出貨、收貨商品 (僅可編輯數量)")]),_vm._l((_vm.localPayload.items.filter(
            ({ shipped }) => shipped !== 0
          )),function(item,n){return _c('v-row',{key:`in-progress-${n}`,attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0 pb-sm-3",attrs:{"cols":"12","sm":"5"}},[_c('v-combobox',{attrs:{"value":item.product?.name || null,"dense":"","outlined":"","items":_vm.productsOptions,"disabled":""},on:{"change":($event) => {
                  item.product = $event.value;
                  item.cost = $event.value?.price || 0;
                }}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"7","sm":"3"}},[_c('v-text-field',{attrs:{"rules":[_vm.validateRequired(item.shipped)],"label":"數量","type":"number","dense":"","outlined":"","clearable":""},model:{value:(item.required),callback:function ($$v) {_vm.$set(item, "required", _vm._n($$v))},expression:"item.required"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"5","sm":"3"}},[_c('v-text-field',{attrs:{"type":"number","label":"金額","dense":"","outlined":"","hide-details":""},model:{value:(item.cost),callback:function ($$v) {_vm.$set(item, "cost", _vm._n($$v))},expression:"item.cost"}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"cursor-not-allowed",attrs:{"color":"#BDBDBD"}},[_vm._v(" mdi-close ")])],1)],1)}),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1)]:_vm._e(),(
          _vm.localPayload.items.filter(({ shipped }) => shipped === 0).length !==
          0
        )?[_c('h3',{staticClass:"my-2"},[_vm._v("未出貨、收貨商品 (可以編輯和刪除)")]),_vm._l((_vm.localPayload.items.filter(
            ({ shipped }) => shipped === 0
          )),function(item,n){return _c('v-row',{key:`editable-${n}`,attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0 pb-sm-3",attrs:{"cols":"12","sm":"5"}},[_c('v-combobox',{attrs:{"value":item.product?.name || null,"dense":"","outlined":"","hide-details":"","items":_vm.productsOptions,"prepend-icon":_vm.$vuetify.breakpoint.xsOnly ? 'mdi-close' : null},on:{"change":($event) => {
                  item.product = $event.value;
                  item.cost = $event.value?.price || 0;
                }}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"7","sm":"3"}},[_c('v-text-field',{attrs:{"label":"數量","type":"number","dense":"","outlined":"","hide-details":"","clearable":"","prepend-icon":_vm.$vuetify.breakpoint.xsOnly ? '  ' : null},model:{value:(item.required),callback:function ($$v) {_vm.$set(item, "required", _vm._n($$v))},expression:"item.required"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"5","sm":"3"}},[_c('v-text-field',{attrs:{"label":"金額","type":"number","dense":"","outlined":"","hide-details":"","clearable":""},model:{value:(item.cost),callback:function ($$v) {_vm.$set(item, "cost", _vm._n($$v))},expression:"item.cost"}})],1),_c('v-col',{staticClass:"text-left d-none d-sm-block",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"pointer",attrs:{"color":"error"},on:{"click":function($event){return _vm.removeItem(item)}}},[_vm._v(" mdi-close ")])],1)],1)})]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"備註","rows":"3","row-height":"25","auto-grow":"","outlined":"","hide-details":""},model:{value:(_vm.localPayload.comment),callback:function ($$v) {_vm.$set(_vm.localPayload, "comment", $$v)},expression:"localPayload.comment"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"large":"","block":"","color":"primary"},on:{"click":_vm.addItem}},[_vm._v(" 增加一筆 ")])],1)],1)],2),_c('v-card-actions',[_c('v-btn',{staticClass:"bt-text-large",attrs:{"text":"","color":"error"},on:{"click":_vm.clear}},[_vm._v(" 取消 ")]),_c('v-spacer'),_c('v-btn',{staticClass:"bt-text-large",attrs:{"text":"","color":"primary"},on:{"click":_vm.editPurchase}},[_vm._v(" 儲存 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }