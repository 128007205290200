<template>
  <v-dialog v-model="value" persistent>
    <v-card>
      <v-card-title>建立採購單</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              label="出貨單位"
              v-model="payload.from"
              :items="fromUnit"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="進貨單位"
              v-model="payload.to"
              :items="unit"
              outlined
              dense
              hide-details
              @change="updateComboboxVal"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row v-for="(item, n) of payload.items" :key="n" align="center">
          <v-col cols="12" sm="5" class="pb-0 pb-sm-3">
            <v-combobox
              :value="item.product?.name || null"
              dense
              outlined
              hide-details
              :items="productsOptions"
              @change="
                ($event) => {
                  item.product = $event.value;
                  item.cost = $event.value?.cost || 0;
                }
              "
              :prepend-icon="$vuetify.breakpoint.xsOnly ? 'mdi-close' : null"
              @click:prepend="removeItem(n)"
            ></v-combobox>
          </v-col>
          <v-col cols="7" sm="3" class="text-center">
            <v-text-field
              v-model.number="item.required"
              type="number"
              label="數量"
              dense
              outlined
              hide-details
              :prepend-icon="$vuetify.breakpoint.xsOnly ? '  ' : null"
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="3" class="text-center">
            <v-text-field
              v-model.number="item.cost"
              type="number"
              label="金額"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left d-none d-sm-block">
            <v-icon class="pointer" color="error" @click="removeItem(n)">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="payload.comment"
              label="備註"
              rows="3"
              row-height="25"
              auto-grow
              outlined
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn large block color="primary" @click="addItem">
              增加一筆
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="clear" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="create" class="bt-text-large">
          建立
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "",
  props: ["value", "branches", "unit", "fromUnit", "products"],
  data: () => ({
    payload: {
      from: null,
      to: "",
      items: [],
      comment: "",
    },
  }),
  computed: {
    productsOptions() {
      if (!this.products) return [];
      return _.map(this.products, (o) => {
        return {
          text: o.name,
          value: o,
        };
      });
    },
  },
  methods: {
    async create() {
      try {
        let payload = _.cloneDeep(this.payload);
        payload.items = _.filter(payload.items, (o) => o.product && o.required);

        if (payload.from === "" || payload.to === "") {
          return this.$toast.error("請選擇出貨單位與進貨單位");
        }
        if (payload.from == payload.to) {
          return this.$toast.error("出貨單位與進貨單位不可相同");
        }
        if (payload.items.length == 0) {
          return this.$toast.error("請新增請購商品及數量");
        }
        if (payload.items.some((item) => !item.cost || !item.required)) {
          return this.$toast.error("請填寫請購商品金額及數量");
        }
        if (payload.items.some((item) => item.cost < 0 || item.required < 0)) {
          return this.$toast.error("請購商品金額及數量不得小於 0");
        }

        const ids = new Set(payload.items.map((item) => item.product._id));
        if (ids.size !== payload.items.length) {
          return this.$toast.error("請勿選擇重複的產品");
        }

        this.$vloading.show();

        if (!payload.comment) {
          payload = _.omit(payload, ["comment"]);
        }

        await this.axios.post(`/purchase/order`, payload);

        this.$toast.success("建立成功");
        this.clear();
        this.$emit("load");
        this.$vloading.hide();
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response?.data?.message || error || "建立失敗");
        this.$vloading.hide();
      }
    },
    clear() {
      this.$emit("input", false);
    },
    updateComboboxVal(event) {
      const val = event.value ? event.value : event;
      this.payload.to = val;
    },
    addItem() {
      this.payload.items.push({
        product: null,
        required: null,
        shipped: 0,
        received: 0,
      });
    },
    removeItem(n) {
      this.payload.items.splice(n, 1);
    },
  },
};
</script>

<style></style>
